export class FeatureFlags {
	AIGeneratedForms: boolean;
	AllowDisableUnreadEntryTracking: boolean;
	UserSpecificViews: boolean;
	GuestUsers: boolean;
	CollectCognitoIndustry: boolean;
	InfoChangeEvents: boolean;
	AppNav: boolean;
	AppNavOptIn: boolean;
	AdminEntryCreation: boolean;
	V2EntriesGrid: boolean;
	EntriesPagePerformanceEnhancements: boolean;
	StripeTax: boolean;
}